.info-panel {
  width: 22rem;
  height: 38rem;
  margin: 0.5rem;
  padding: 0rem;
  border-radius: 0.625rem;

  background: #211f2d;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
}

.info-panel.wide {
  width: 26rem;
}

.info-header {
  height: 4.5rem;
  padding: 0.5rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-header-hr {
  margin-top: 0.6rem;
  border: 0.5px solid #807d8e;
  width: 90%;
}

.info-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30rem;
}

/* ANCHOR MOBILE */
@media only screen and (max-width: 800px) {
  .info-panel {
    width: 20rem;
  }
}
