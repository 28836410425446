/* .membership-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    gap: 0.8rem;
    flex-grow: 0;
} */

.or-div {
  display: flex;
  width: 80%;
}

.or-hr {
  margin: auto 0.3rem auto 0.3rem;
  border: 0.5px solid #807d8e;
  width: 100%;
}

p.btn-margin {
  margin-top: 0.5rem;
}

.subscription-container {
  height: 20rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subscription-copy {
  text-align: center;
}

.license-container {
  height: 20rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.license-copy {
  text-align: center;
}

.purchase-btn-container {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  margin: 0.8rem 2rem 0rem 2rem;
}

.payment-pending-container {
  height: 20rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.prorate-container {
  height: 20rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.coupon-container {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.confirm-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}

.trial-user-payment-query {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 85%;
}
.redeem-title {
  text-align: center;
  margin: 0.6rem;
}

.redeem-license-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

input.license-input {
  opacity: 1;
  border: 2px solid #32303b;
  border-radius: 4px;
  background-color: #201c24;
  color: rgba(255, 255, 255, 0.5);
  height: 2rem;
  font-size: 1rem;
  /* font-family: 'Courier New', Courier, monospace; */
  padding-left: 0.2rem;
  padding-right: 0rem;
  margin-bottom: 0.2rem;
  margin-right: 0rem;
  width: 12rem;
}
