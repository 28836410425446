.header-flex {
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem 0rem 1rem;
  margin-bottom: 1rem;
  gap: 3rem;
  align-items: center;
}

.header-spacer {
  flex-grow: 1
}

.header-greeting {
  display: flex;
  flex-direction: row;
  align-items: end;
  padding-bottom: 0.2rem;
  margin-top: auto;
  gap: 1rem;
}

#logout-link {
  margin-bottom: 0.15rem;
  font-size: 120%;
}

.musio-download-container {
  display: flex;
  gap: 0.5rem;
  height: 3rem;
  margin-right: 0.5rem;
}

.musio-download-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-width: 8rem;
  max-height: 1rem;
}

.download-prompt-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

}

/* ANCHOR MOBILE */
@media only screen and (max-width: 800px) {
  .header-flex {
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    margin-bottom: 1rem;
  }

  .musio-download-container {
    flex-direction: column;
    width: 70%;
    height: 14rem;
  }

  #mobile-user-download-message {
    background-color: #fb2545;
    color: white;
    text-align: center;
    max-width: 500px;
    margin: auto;
    padding: 10px;
    border-radius: 15px;
    border: solid white 1px;
    margin-top: 10px;
  }

  #mobile-user-download-message h4 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
