
.checkbox-div {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor:pointer;
    
    min-width: 40px;
    margin: auto;
    padding: 0;
}

.checkbox-hidden {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    /* margin-top:0rem; */
}

.musio-checkmark {
    position: absolute;
    opacity: 1;
    margin-top: -0.3rem;
    margin-left: -0.4rem;
    height: 25px;
    width: 25px;
    border: 2px solid #54535C;
    border-radius:.2rem;
}

.checkbox-div input:checked ~ .musio-checkmark{
    background-color:  var(--inputbg);
}
  
.checkbox-div:hover input ~ .musio-checkmark {
    background-color: rgb(80, 80, 80);
}
  
.checkbox input:checked ~ .musio-checkmark {
    background-color: var(--inputcolor);
}
  
.musio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-div input:checked ~ .musio-checkmark:after {
    display: block;
}

.checkbox-div .musio-checkmark:after {
    left: 0.53rem;
    top: 0.2rem;
    width: .3rem;
    height: .8rem;
    border: solid var(--white);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}