.musio-button.button {
  cursor: pointer;
  opacity: 1!important;
  color: var(--white);
  font-weight: 600;
  border: 0;

  -webkit-box-shadow: inset 0 .7px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: inset 0 .7px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2) !important;
  box-shadow: inset 0 .7px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2) !important;
  -webkit-transition-duration: 0.2s !important;
  -moz-transition-duration: 0.2s !important;
  transition-duration: 0.2s !important;
}

.musio-button.button.round {
    border-radius: 150px;
    height: 50px;
    /* margin-right: auto; */
    padding: 0rem 2rem 0rem 2rem;
    min-width: 10rem;
}

.musio-button.button.squared {
    border-radius: .7rem;
    padding: .6rem 1.1rem .6rem 1.1rem;
    min-width: 6rem;
}

.musio-button.button.grey {
  background-color: var(--grey);
}

.musio-button.button.red {
  background-color: var(--red);
}

.musio-button.button.large {
  font-size: 1.2rem;
}

.musio-button.button.small {
  font-size: 1.0rem;
}


.musio-button.button:hover:enabled, input[type='submit']:hover,#changePwSubmit:hover, #add-user-submit:hover {
    background-color: #bb2c3b !important;
}
  
.musio-button.button:active:enabled, input[type='submit']:active, #changePwSubmit:active, #add-user-submit:active {
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.6)!important;
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.6)!important;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.6)!important;
}

.musio-button.button:disabled {
  background-color:grey;
}

.button-container {
  position: relative;
}

.text-hidden {
  opacity: 0;
}

.button-overlay {
  z-index: 9;
  margin: auto;
  position: absolute;
  text-align:center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;

}

.overlay-hidden {
  display: none;
}