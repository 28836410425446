.musio-edit.button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

#pencil-svg:hover path, #pencil-svg:hover rect{
    fill: var(--red);
}

#pencil-svg:active path, #pencil-svg:active rect{
    fill: var(--white)
}