/* css reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* END CSS RESET */

:root {
  --white: #e4e4e4;
  --height: 100vh;
  --width: 100vw;
  --black: #201c24;
  --red: #fb2645;
  --font: 1.2rem;
  --weight: 400;
  --grey: #32303b;
}

html {
  background-color: var(--black);
  min-width: 100vw;
  color: var(--white);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 30px;
  font-weight: 700;
}

h2 {
  font-size: 25px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

p {
  margin-top: 0.2rem;
  line-height: 1.2rem;
}

p.subtext,
span.subtext,
h3.subtext,
s.subtext {
  opacity: 0.5;
}

.subtletext {
  opacity: 0.5;
  font-style: italic;
}

p.redtext,
span.redtext,
h1.redtext,
h2.redtext,
h3.redtext {
  color: var(--red);
}

p.centered {
  text-align: center;
}

a.musio-link {
  color: var(--red);
  text-decoration-color: var(--red);
  text-underline-offset: 0.11rem;
  opacity: 1;
}

div.App {
  position: absolute;
  height: 100vh;
  width: 100vw;
  min-width: 600px;
  background-image: url("data:image/svg+xml,%3csvg width='2485' height='1200' viewBox='0 -330 1400 900' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='2125.8' y='-282' width='242.561' height='2453.69' transform='rotate(60 2125.8 -282)' fill='%23461825'/%3e%3cpath d='M1404.49 -33L864.087 279' stroke='white'/%3e%3cpath d='M1147.4 691L607.001 1003' stroke='white'/%3e%3cpath d='M1039.49 110L924.262 176.525' stroke='%23FB2645'/%3e%3cpath d='M1211.6 724L1157 755.525' stroke='%23FB2645'/%3e%3cpath d='M822.604 404L768.001 435.525' stroke='%23FB2645'/%3e%3c/svg%3e");
  background-position: 60% 80%;
  background-repeat: no-repeat;
}

div.center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.-internal-light-dark {
  background-color: var(--black);
}

/* ANCHOR MOBILE */
@media only screen and (max-width: 500px) {
  div.App {
    min-width: 250px;
    background-image: initial;
  }
}
