.dashboard-container {
  padding-bottom: 4rem;
}
.dashboard-flex {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
}
