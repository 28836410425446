.user-info-container {
    /* margin: 2rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 80%;
}

.user-spacer {
    height: 3rem;
}

.user-button {
    margin: auto auto 0.0rem auto;
    /* text-align: center; */
}



/* private UserProperty style */
.user-property-flex {
    display: flex;
    flex-direction: row;
    /* gap: 0.2rem; */
}

.user-property-text {
    display: flex;
    flex-direction: column;
}

.user-property-edit {
    margin: auto 0 0.4rem auto;
}

.user-edit-form {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.user-edit-title {
    margin-bottom: 0.2rem;
}

input.edit-user-info {
    opacity: 1;
    border: 2px solid #32303B;
    border-radius: 4px;
    background-color: #201c24;
    color: rgba(255, 255, 255, 0.5);
    height: 2.0rem;
    font-size: 1rem;
    /* font-family: 'Courier New', Courier, monospace; */
    padding-left: 0.2rem;
    padding-right: 0.0rem;
    margin-bottom: 0.2rem;
    margin-right: 0rem;
    width: 80%;
}